import Provider from 'pubsub-js'
import FontFaceObserver from 'fontfaceobserver'
import {
  APPLY_BACKGROUND,
  BACKGROUND_COLOR_CHANGED,
  BEFORE,
  CANVAS_MODIFIED,
  CROP_APPLYIED,
  FILTER_CHANGED,
  HAS_COPIED_OBJECT,
  MOUSE_DOWN,
  OBJECT_ADDED,
  OBJECT_MODIFIED,
  OBJECT_MOVING,
  OBJECT_REMOVED,
  OBJECT_ROTATING,
  OBJECT_SCALING,
  OPACITY_CHANGED,
  SCALLED_UP_IMAGES,
  SELECTION_CLEARED,
  SELECTION_CREATED,
  SELECTION_UPDATED,
  STATIC_OBJECT_FILL_CHANGED,
  STROKE_CHANGED,
  TEXT_ADDED,
  TEXT_CHANGED,
  TOUCH_MOVE,
} from '../constants/event-types'
import Canvas from './Canvas'
import productSettings from './product-settings'
import ObjectResizer from './lib/object-resizer'
import initAligningGuidelines from './lib/aligning-guidlines'
import initCenteringGuidelines from './lib/centering-guidlines'
import { API_BACKGROUNDS } from '../constants/api'
import CommandHistory from './lib/undo-redo/command-history'
import TransformCommand from './lib/undo-redo/transform-command'
import AddCommand from './lib/undo-redo/add-command'
import RemoveCommand from './lib/undo-redo/remove-command'
import SetBgCommand from './lib/undo-redo/set-bg-command'
import CreateSelectionCommand from './lib/undo-redo/create-selection-command'
import ClearSelectionCommand from './lib/undo-redo/clear-selection.command'
import getEffectByName from './lib/get-effect-by-name'
import trackEvent from '../../../utils/track-event'
import isTransparent from './lib/is-transparent'
import CreateTilingCommand from './lib/undo-redo/create-tiling-command'
import DestroyTilingCommand from './lib/undo-redo/destroy-tiling-command'

const excludedFromLoadingFonts = [`Arial`]

class Mediator {
  _assetsMemory = {}
  isDesignSaved = true
  offsetOfPastedObject = 10
  initCanvas(options) {
    const canvas = Canvas.init(options)
    initAligningGuidelines(canvas)
    initCenteringGuidelines(canvas)
    this._initCommandHistory()
    this._addSelectionObjectByRightClick()
    this._initPublisherForCanvas()
    this._initOnBeforeUnloadWarning()

    // only for dev
    window.__canvas = canvas
    window.__mediator = this
  }
  getCanvas() {
    return Canvas.canvas
  }
  addToCanvas(obj) {
    this.getCanvas().add(obj)
    return this
  }
  getProductAssets(options) {
    const url = this._getBgURL(options)
    if (this._assetsMemory[url]) {
      return this._assetsMemory[url]
    }
    return fetch(url)
      .then((res) => {
        if (res.status >= 400) {
          throw new Error(`Error`)
        }
        const json = res.json()
        this._assetsMemory[url] = json
        return json
      })
      .catch((error) => {
        return error
      })
  }
  async applyAssetsToCanvas({ background, ...rest }) {
    try {
      // TODO: need to come only overlay
      const { overlay, lamination, corner_radius: cornerRadius } = rest
      const hasOverlayImage = overlay || lamination || cornerRadius
      if (background) {
        Provider.publish(APPLY_BACKGROUND, background)
      }
      if (hasOverlayImage) {
        if (overlay) {
          await Canvas.setOverlayImage(overlay)
        }
        if (lamination) {
          await Canvas.setOverlayImage(lamination)
        }
        if (cornerRadius) {
          await Canvas.setOverlayImage(cornerRadius)
        }
      } else {
        await Canvas.setOverlayImage(null)
      }
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    } catch (e) {
      throw new Error(e)
    }
  }
  async applyDesignToCanvas(json, canvas) {
    try {
      if (json) {
        if (!json.version) {
          json = this._transformOldJSON(json)
        }
        this.exitTextEditing(json)
        await this.loadUsedFonts(json)
        await Canvas.applyDesign(json, canvas)
      }
    } catch (e) {
      throw new Error(e)
    }
  }
  async applyClippingToCanvas(settings) {
    if (settings && settings.guideLines) {
      const guideLineOffset =
        settings.guideLines === true ? 0 : settings.guideLines
      Canvas.applyClipping(guideLineOffset)
    } else {
      Canvas.applyClipping()
    }
  }
  applyObjectResizer(dimensions, dpi) {
    ObjectResizer.setOptions({ dimensions, dpi })
  }
  async applyConditionToCanvas(
    { product, side, variants, design, dimensions, dpi },
    prevCondition = {}
  ) {
    try {
      this.condition = { product, side, variants, design, dimensions, dpi }
      const { design: prevDesign } = prevCondition
      if (JSON.stringify(design) !== JSON.stringify(prevDesign)) {
        await this.applyDesignToCanvas(design, this.getCanvas())
        Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
        this.clearHistory()
        this.offsetOfPastedObject = 0
      }
      this.applyObjectResizer(dimensions, dpi)
      Canvas.setDimensions(dimensions)
      const assets = await this.getProductAssets({
        product,
        style: `large`,
        side,
        variants,
      })
      if (assets) {
        await this.applyAssetsToCanvas(assets)
        await this.applyClippingToCanvas(productSettings[product])
      }
      Canvas.markHiddenObjects()
      Canvas.renderAll()
      this.checkScaledUpImages()
    } catch (e) {
      throw e
    }
  }
  undo() {
    this.history.back()
    Canvas.renderAll()
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Undo`,
    })
    return this.getStateOfHistory()
  }
  redo() {
    this.history.forward()
    Canvas.renderAll()
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Redo`,
    })
    return this.getStateOfHistory()
  }
  selectObjects(objects) {
    const selection = Canvas.selectObjects(objects)
    Provider.publish(SELECTION_CREATED, selection)
    return selection
  }
  unSelectObjects() {
    Canvas.unSelectObjects()
    Provider.publish(SELECTION_CLEARED)
  }
  getStateOfHistory() {
    const { index, commands } = this.history
    return {
      index,
      lengthOfCommands: commands.length,
    }
  }
  clearHistory() {
    this.history.clear()
  }
  getActive() {
    return Canvas.getActive()
  }
  removeActive() {
    const activeObject = this.getActive()
    if (activeObject) {
      this.removeObject(activeObject)
      this.addToHistory(`clearSelection`, activeObject)
      this.addToHistory(`remove`, activeObject)
      Canvas.discardActiveObject()
      this.checkScaledUpImages()
      Provider.publish(OBJECT_REMOVED)
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
      trackEvent({
        category: `Editor`,
        action: `Remove object`,
      })
    }
  }
  removeAllExceptActive() {
    Canvas.removeAllExceptObject(Canvas.getActive(), (object) => {
      this.addToHistory(`remove`, object)
      this.checkScaledUpImages()
    })
  }
  hasCopiedObject() {
    return !!this.objectToCopy
  }
  async pasteCopiedObject() {
    const { product, side } = this.condition
    const settings = productSettings[product]
    const onlyBg =
      settings &&
      settings.permissions &&
      settings.permissions[side] &&
      !settings.permissions[side].image &&
      !settings.permissions[side].text

    if (this.hasCopiedObject() && !onlyBg) {
      const object = await Canvas.cloneObject(this.objectToCopy)
      if (Canvas.getActive()) {
        this.addToHistory(`clearSelection`, Canvas.getActive())
      }
      const addedObject = Canvas.addObject(object, {
        offsetX: this.offsetOfPastedObject,
        offsetY: this.offsetOfPastedObject,
      })
      const selection = Canvas.selectObjects(addedObject)
      this.addToHistory(`add`, selection)
      this.addToHistory(`createSelection`, selection)
      Provider.publish(SELECTION_CREATED, Canvas.getActive())
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    }
  }
  async copyActiveObject() {
    this.objectToCopy = await Canvas.cloneObject(Canvas.getActive())
    Provider.publish(HAS_COPIED_OBJECT)
    this.offsetOfPastedObject = 10
  }
  selectAllObjects() {
    if (Canvas.getActive()) {
      this.addToHistory(`clearSelection`, Canvas.getActive())
    }
    Canvas.selectAllObjects()
    this.addToHistory(`createSelection`, Canvas.getActive())
    Provider.publish(SELECTION_CREATED, Canvas.getActive())
  }
  async cloneActiveObject() {
    await this.copyActiveObject()
    this.pasteCopiedObject()
  }
  flipHorizontally() {
    const active = this.getActive()
    Canvas.flipHorizontally(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Flip horizontally`,
    })
  }
  flipVertically() {
    const active = this.getActive()
    Canvas.flipVertically(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Flip vertically`,
    })
  }
  straightenActive() {
    const active = this.getActive()
    Canvas.straightenObject(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Straighten`,
    })
  }
  sendActiveToBack() {
    const active = this.getActive()
    Canvas.sendObjectToBack(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Send to back`,
    })
  }
  sendActiveToBackwards() {
    const active = this.getActive()
    Canvas.sendObjectToBackwards(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Send backwards`,
    })
  }
  bringActiveToFront() {
    const active = this.getActive()
    Canvas.bringObjectToFront(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Bring to front`,
    })
  }
  bringActiveToForward() {
    const active = this.getActive()
    Canvas.bringObjectToForward(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Bring forward`,
    })
  }
  centerActiveVertically() {
    const active = this.getActive()
    Canvas.centerObjectVertically(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
  }
  centerActiveHorizontally() {
    const active = this.getActive()
    Canvas.centerObjectHorizontally(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
  }
  resizeActiveObjectToFill() {
    const active = this.getActive()
    Canvas.resizeObjectToFill(active)
    this.addToHistory(`transform`, active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    this.checkScaledUpImages()
    trackEvent({
      category: `Editor`,
      action: `Resize to fill`,
    })
  }
  restoreRatioOfActiveObject() {
    const active = this.getActive()
    Canvas.restoreRatioOfObject(active)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    this.addToHistory(`transform`, active)
    trackEvent({
      category: `Editor`,
      action: `Restore ratio`,
    })
  }
  setBackgroundColor(color, silent) {
    if (!silent) {
      Canvas.saveState()
    }
    Canvas.setBackgroundColor(color)
    if (!silent) {
      this.addToHistory(`setBg`, this.getCanvas())
    }
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    Provider.publish(BACKGROUND_COLOR_CHANGED, color)
    if (isTransparent(color)) {
      trackEvent({
        category: `Editor`,
        action: `Remove background`,
      })
    } else {
      trackEvent({
        category: `Editor`,
        action: `Add background`,
      })
    }
  }
  async addPictureToCanvas(
    { id, type, original, canvasUrl, originalWidth, originalHeight },
    options,
    fields = {}
  ) {
    let urlToPicture = canvasUrl || original
    const pictureOptions = canvasUrl
      ? {
          imageType: type,
          originalSrc: original,
        }
      : {
          imageType: type,
        }

    if (urlToPicture.endsWith('png') && type === 'image/svg+xml') {
      urlToPicture = original
    }

    const picture =
      type === `image/svg+xml`
        ? await Canvas.loadSVGFromURL(urlToPicture, options)
        : await Canvas.loadImageFromURL(urlToPicture, pictureOptions)

    picture.remoteId = id
    picture.fields = fields

    if (options.isTexture) {
      ObjectResizer.resizeToFill(picture)
      trackEvent({
        category: `Editor`,
        action: `Add texture`,
        label: original,
      })
    } else if (options.isClipart) {
      trackEvent({
        category: `Editor`,
        action: `Add clipart`,
        label: original,
      })

      ObjectResizer.resizeToFit(picture, originalWidth, originalHeight)
    } else if (options.isEmoji) {
      picture.scaleToWidth(options.scaleWidth)
      trackEvent({
        category: `Editor`,
        action: `Add emoji`,
        label: original,
      })
    } else if (options.isFigure) {
      picture.scaleToWidth(options.scaleWidth)
      trackEvent({
        category: `Editor`,
        action: `Add figure`,
        label: original,
      })
    } else {
      trackEvent({
        category: `Editor`,
        action: `Add own image`,
        label: original,
      })
      ObjectResizer.resizeToFit(picture, originalWidth, originalHeight)
    }
    Canvas.centerObjectHorizontally(picture)
    Canvas.centerObjectVertically(picture)
    if (options.fill && this.isOneColorPath(picture)) {
      Canvas.setFillForPath(picture, options.fill)
    }
    if (Canvas.getActive()) {
      this.addToHistory(`clearSelection`, Canvas.getActive())
    }
    this.addToHistory(`add`, picture)
    Canvas.add(picture)
    this.addToHistory(`createSelection`, picture)
    this.selectObjects(picture)
    this.checkScaledUpImages()
  }
  async addObjectsToCanvas({ original }) {
    const design = await fetch(original).then((response) => response.json())
    await this.loadUsedFonts(design)
    const enlivenedObjects = await Canvas.enlivenObjects(design.objects)

    enlivenedObjects.map((obj) => {
      Canvas.addObject(obj)
      this.addToHistory(`add`, obj)
    })

    Canvas.renderAll()

    const selection = this.selectObjects(enlivenedObjects)
    this.addToHistory(`createSelection`, selection)

    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())

    trackEvent({
      category: `Editor`,
      action: `Add lettering`,
      label: original,
    })
  }
  async addTextToCanvas(text, options) {
    await this.loadFont(`AA Magnum`)
    const oText = await Canvas.createText(text, {
      fontFamily: `AA Magnum`,
      textAlign: `center`,
      fontSize: 30,
      lineHeight: 1.1,
      ...options,
    })
    Canvas.centerObjectHorizontally(oText)
    Canvas.centerObjectVertically(oText)
    if (Canvas.getActive()) {
      this.addToHistory(`clearSelection`, Canvas.getActive())
    }
    this.addToHistory(`add`, oText)
    Canvas.add(oText)
    this.addToHistory(`createSelection`, oText)
    this.selectObjects(oText)
    if (oText.editable) {
      oText.enterEditing()
    } else {
      Provider.publish(TEXT_ADDED)
    }
  }
  loadFont(fontName) {
    if (excludedFromLoadingFonts.includes(fontName)) {
      return
    }
    const font = new FontFaceObserver(fontName)
    return font.load(null, 5000).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(`${fontName} is not available: ${err}`)
    })
  }
  async loadUsedFonts(design) {
    const fontsUsedInDesign = [`Helvetica`]
    design.objects.forEach((obj) => {
      if (obj.fontFamily && fontsUsedInDesign.indexOf(obj.fontFamily) === -1) {
        fontsUsedInDesign.push(obj.fontFamily)
      }
      if (obj.tile) {
        obj.tile.objects.forEach((o) => {
          if (o.fontFamily && fontsUsedInDesign.indexOf(o.fontFamily) === -1) {
            fontsUsedInDesign.push(o.fontFamily)
          }
        })
      }
    })
    return Promise.all(fontsUsedInDesign.map(this.loadFont))
  }
  checkScaledUpImages() {
    Provider.publish(SCALLED_UP_IMAGES, this._hasScaledUpImages())
  }
  clearDesign() {
    this.setBackgroundColor(`transparent`)
    this.selectAllObjects()
    this.removeActive()
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
  }
  getVariantsFromURL() {
    if (window && window.location.search.length) {
      return window.location.search.split(`&`).reduce((acc, item, i) => {
        const [key, value] =
          i === 0 ? item.slice(1).split(`=`) : item.split(`=`)
        if (value === ``) {
          return { ...acc }
        }
        return { ...acc, [key.replace(`default_`, ``)]: value }
      }, {})
    }
    return void 0
  }
  addObject(object) {
    Canvas.addObject(object)
    Canvas.renderAll()
  }
  removeObject(object) {
    Canvas.discardActiveObject()
    Canvas.removeObject(object)
    Canvas.renderAll()
  }
  saveObjectState(object) {
    object.saveState()
    if (object.isType(`activeSelection`) || object.isType(`group`)) {
      object.getObjects().forEach((obj) => {
        obj.saveState()
      })
    }
  }
  addToHistory(command, object) {
    switch (command) {
      case `transform`:
        this.history.add(new TransformCommand(object))
        this.saveObjectState(object)
        break
      case `remove`:
        this.saveObjectState(object)
        this.history.add(new RemoveCommand(object, this))
        break
      case `add`:
        this.saveObjectState(object)
        this.history.add(new AddCommand(object, this))
        break
      case `setBg`:
        this.history.add(new SetBgCommand(object, this))
        break
      case `createSelection`:
        this.history.add(new CreateSelectionCommand(object, this))
        break
      case `clearSelection`:
        this.history.add(new ClearSelectionCommand(object, this))
        break
      case `createTiling`:
        this.history.add(new CreateTilingCommand(object, this))
        break
      case `destroyTiling`:
        this.history.add(new DestroyTilingCommand(object, this))
    }
  }
  moveActiveObjectTo(direction, delta) {
    const activeObject = Canvas.getActive()
    if (activeObject) {
      Canvas.moveObjectTo(activeObject, direction, delta)
      Canvas.renderAll()
      this.history.add(new TransformCommand(activeObject))
      Provider.publish(OBJECT_MOVING, activeObject)
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    }
  }
  setActiveTextProperty(property, value) {
    const active = this.getActive()

    if (this.isText(active)) {
      this.addToHistory(`transform`, active)
      active.set(property, value)
      Canvas.renderAll()
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
      if (property === `fontFamily`) {
        trackEvent({
          category: `Editor`,
          action: `Font change`,
          label: value,
        })
      }
      if (property === `fill`) {
        trackEvent({
          category: `Editor`,
          action: `Font color change`,
        })
      }
    }
  }
  isText(object) {
    return (
      object.isType(`textbox`) ||
      object.isType(`i-text`) ||
      object.isType(`text`)
    )
  }
  isImage(object) {
    return object.isType(`image`) && object.imageType !== `application/svg`
  }
  isPath(object) {
    return (
      object.isType(`path`) ||
      object.isType(`circle`) ||
      object.isType(`rect`) ||
      object.isType(`ellipse`) ||
      object.isType(`polygon`) ||
      object.isType(`line`) ||
      object.isType(`polyline`)
    )
  }
  isPathGroup(object) {
    return (
      object.isType(`group`) &&
      object
        .getObjects()
        .every(
          (obj) =>
            obj.type === `path` ||
            obj.type === `circle` ||
            obj.type === `rect` ||
            obj.type === `ellipse` ||
            obj.type === `polygon` ||
            obj.type === `line` ||
            obj.type === `polyline`
        )
    )
  }
  isOneColorPath(object) {
    if (this.isPathGroup(object)) {
      const objects = object.getObjects()
      const colorOfFirtPath = objects[0].fill
      return objects.every((obj) => obj.fill === colorOfFirtPath)
    } else if (this.isPath(object)) {
      return true
    }
    return false
  }
  setFillForActivePath(value) {
    const active = this.getActive()
    if (
      this.isOneColorPath(active) &&
      (this.isPathGroup(active) || this.isPath(active))
    ) {
      Canvas.setFillForPath(active, value)
      Canvas.renderAll()
      this.addToHistory(`transform`, active)
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
      trackEvent({
        category: `Editor`,
        action: `Clipart color change`,
      })
    }
  }
  toggleEffect(effectName) {
    const effect = getEffectByName(effectName)
    const { id, value, undoTitle, makeTitle } = effect
    const activeObject = this.getActive()
    if (Canvas.isFilter(effect.value)) {
      Canvas.addFilterToObject(activeObject, {
        id,
        filter: activeObject.filters[id] ? null : value,
      })
      trackEvent({
        category: `Editor`,
        action: activeObject.filters[id] ? makeTitle : undoTitle,
      })
    } else {
      const clipPath = value(activeObject)
      if (this.isActiveClipPath(activeObject.clipPath, id)) {
        this.getActive().clipPath = null
        Canvas.renderAll()
      } else {
        this.getActive().clipPath = null
        Canvas.renderAll()
        Canvas.addClipPathToObject(this.getActive(), clipPath)
      }
    }
    Canvas.renderAll()

    Provider.publish(FILTER_CHANGED, activeObject)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
  }
  isActiveClipPath(clipPath, id) {
    return clipPath && String(clipPath).indexOf(id) > -1
  }
  setOpacityToActive(value) {
    Canvas.setOpacityToObject(this.getActive(), value)
    Canvas.renderAll()
    this.addToHistory(`transform`, this.getActive())
    Provider.publish(OPACITY_CHANGED, this.getActive())
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
  }
  setStrokeToActive(value) {
    Canvas.setStrokeToObject(this.getActive(), value)
    Canvas.renderAll()
    this.addToHistory(`transform`, this.getActive())
    Provider.publish(STROKE_CHANGED, this.getActive())
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Stroke`,
    })
  }
  async applyCropToActiveObject(cropUrl) {
    const active = this.getActive()
    const clipPath = cropUrl ? await Canvas.loadSVGFromURL(cropUrl) : null
    const { width, height } = active

    if (clipPath) {
      clipPath.id = cropUrl
      clipPath.top = 0
      clipPath.left = 0

      const angleOfRadian = (Math.PI / 180) * (clipPath.angle % 180)

      const clipPathWidth =
        Math.cos(angleOfRadian) * clipPath.width +
        Math.sin(angleOfRadian) * clipPath.height

      const clipPathHeight =
        Math.sin(angleOfRadian) * clipPath.width +
        Math.cos(angleOfRadian) * clipPath.height

      const scaleFactor = Math.min(
        width / clipPathWidth,
        height / clipPathHeight
      )

      clipPath.scale(scaleFactor)
      trackEvent({
        category: `Editor`,
        action: `Add crop`,
      })
    } else {
      trackEvent({
        category: `Editor`,
        action: `Remove crop`,
      })
    }
    active.clipPath = null
    Canvas.renderAll()
    Canvas.addClipPathToObject(active, clipPath)
    Canvas.renderAll()
    this.addToHistory(`transform`, active)
    Provider.publish(CROP_APPLYIED, this.getActive())
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
  }
  setFillForPathOfStaticObject(fillColor, pathNumber) {
    const staticObject = Canvas.getObjects().filter((o) => o.static)[0]
    staticObject.set(`fill`, fillColor)
    if (staticObject.isType(`group`)) {
      staticObject.getObjects()[pathNumber].set(`fill`, fillColor)
    }
    this.addToHistory(`transform`, staticObject)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    Provider.publish(STATIC_OBJECT_FILL_CHANGED, staticObject)
    trackEvent({
      category: `Editor`,
      action: `Static object color change`,
    })
    Canvas.renderAll()
  }
  loadSVGFromURL(...rest) {
    return Canvas.loadSVGFromURL(...rest)
  }
  destroyTilingFromActiveObject() {
    const activeObject = this.getActive()
    const originalObject = this.destroyTiling(activeObject)
    Provider.publish(SELECTION_UPDATED, {
      selected: [originalObject],
      deselected: [activeObject],
    })
    this.addToHistory(`destroyTiling`, activeObject)
    if (!activeObject.isType(`tiling`)) {
      this.addToHistory(`destroyTiling`, originalObject.tiling)
    }
    trackEvent({
      category: `Editor`,
      action: `Destroy tiling`,
    })
  }
  destroyTiling(object) {
    return Canvas.destroyTiling(object)
  }
  async createTilingFromActiveObject(typeOfPattern) {
    const activeObject = this.getActive()
    const tiling = await this.createTilingFromObject(
      activeObject,
      typeOfPattern
    )
    if (!activeObject.isType(`tiling`)) {
      this.addToHistory(`createTiling`, activeObject)
    }
    Provider.publish(SELECTION_UPDATED, {
      selected: [tiling],
      deselected: [activeObject],
    })
    Canvas.renderAll()
    trackEvent({
      category: `Editor`,
      action: `Create tiling`,
      label: typeOfPattern,
    })
  }
  async createTilingFromObject(object, typeOfPattern = `basic`) {
    return await Canvas.createTilingFromObject(object, typeOfPattern)
  }
  isTiling(object) {
    return object.isType(`tiling`)
  }
  setTilePaddingToActive(value) {
    const activeObject = this.getActive()
    Canvas.setTilePadding(activeObject, value)
    Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    trackEvent({
      category: `Editor`,
      action: `Change tile padding`,
    })
  }
  exitTextEditing(design) {
    design.objects
      .filter((object) => object.type === `i-text` || object.type === `textbox`)
      .map((oText) => {
        oText.selectable = true
        oText.selected = false
        oText.isEditing = false
      })
    Canvas.renderAll()
  }
  setSelectionMode(modeValue) {
    this.getCanvas().selection = modeValue
  }
  // private
  _initCommandHistory() {
    this.history = window.__history = new CommandHistory()
  }
  _initPublisherForCanvas() {
    const canvas = this.getCanvas()

    canvas.on(`object:modified`, ({ target }) => {
      this.addToHistory(`transform`, target)
      if (this.isText(target)) {
        const {
          __lineHeights: [heightFirstLine],
          _fontSizeMult: mult,
          lineHeight,
          scaleY,
        } = target
        const fontSize = Math.round(
          ((heightFirstLine / lineHeight) * scaleY) / mult
        )
        target.set({
          scaleX: 1,
          scaleY: 1,
          fontSize,
        })
        Canvas.renderAll()
        Provider.publish(TEXT_CHANGED, target)
      }
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
      Provider.publish(OBJECT_MODIFIED, Canvas.toJSON())
      Canvas.markHiddenObjects()
    })
    canvas.on(`object:added`, () => {
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
      Provider.publish(OBJECT_ADDED)
    })
    canvas.on(`object:removed`, () => {
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
      Provider.publish(OBJECT_REMOVED)
    })
    canvas.on(`object:moving`, (e) => {
      Provider.publish(OBJECT_MOVING, e.target)
    })
    canvas.on(`object:rotating`, (e) => {
      Provider.publish(OBJECT_ROTATING, e)
    })
    canvas.on(`object:scaling`, ({ target }) => {
      Provider.publish(OBJECT_SCALING, target)
      this.checkScaledUpImages()
    })
    canvas.on(`selection:created`, ({ target }) => {
      this.addToHistory(`createSelection`, target)
      if (target.isType(`activeSelection`)) {
        this.saveObjectState(target)
      }
      Provider.publish(SELECTION_CREATED, target)
    })
    canvas.on(`before:selection:cleared`, ({ target }) => {
      this.addToHistory(`clearSelection`, target)
    })
    canvas.on(`selection:cleared`, () => {
      Provider.publish(SELECTION_CLEARED)
      this.getCanvas().upperCanvasEl.removeEventListener(
        `touchmove`,
        this._preventDefault
      )
    })
    canvas.on(`mouse:over`, ({ target }) => {
      const active = this.getActive()
      if (target && target !== active) {
        target.hasControls = false
        target._renderControls(this.getCanvas().contextContainer)
      }
    })
    canvas.on(`mouse:out`, () => {
      Canvas.renderAll() // for removing controls
    })
    canvas.on(`mouse:down`, ({ target }) => {
      Provider.publish(MOUSE_DOWN, target)
      if (target) {
        if (!target.isEditing) {
          target.hasControls = true
        }
        // fix scroll
        this.getCanvas().upperCanvasEl.addEventListener(
          `touchmove`,
          this._preventDefault
        )
      } else {
        this.getCanvas().upperCanvasEl.removeEventListener(
          `touchmove`,
          this._preventDefault
        )
        this.getCanvas().upperCanvasEl.addEventListener(
          `touchmove`,
          this._touchMove
        )
      }
    })
    canvas.on(`selection:updated`, ({ selected, deselected }) => {
      this.addToHistory(`clearSelection`, deselected[0])
      this.addToHistory(`createSelection`, selected[0])
      Provider.publish(SELECTION_UPDATED, { selected, deselected })
    })
    canvas.on(`text:changed`, ({ target }) => {
      Provider.publish(TEXT_CHANGED, target)
      Provider.publish(CANVAS_MODIFIED, Canvas.toJSON())
    })
    canvas.on(`text:editing:entered`, ({ target }) => {
      target.hiddenTextarea.addEventListener(`keypress`, this._stopPropagation)
      target.hiddenTextarea.addEventListener(`keydown`, this._stopPropagation)
    })
    canvas.on(`before:render`, () => {
      Canvas.renderHiddenObjects()
    })
    Provider.subscribe(CANVAS_MODIFIED, () => {
      this.isDesignSaved = false
    })
  }
  _touchMove() {
    Provider.publish(TOUCH_MOVE)
  }
  _stopPropagation(e) {
    e.stopPropagation()
  }
  _preventDefault(e) {
    e.preventDefault()
  }
  _getBgURL({ product, style, side, variants }) {
    let url = `${API_BACKGROUNDS}?id=${product}&style=${style}&side=${side}`
    for (let prop in variants) {
      if ({}.hasOwnProperty.call(variants, prop)) {
        url += `&variant[${prop}]=${variants[prop]}`
      }
    }
    return url
  }
  _hasScaledUpImages() {
    const canvas = this.getCanvas()
    const list = []
    let result = 0
    canvas.forEachObject((obj) => {
      const isBelowMinDPI = ObjectResizer.isBelowMinDPI(obj)
      result += isBelowMinDPI
      if (isBelowMinDPI) {
        list.push(obj)
      }
    })
    return { result: !!result, list }
  }
  _addSelectionObjectByRightClick() {
    const canvas = this.getCanvas()
    canvas.on(`mouse:down`, (e) => {
      if (e.button === 3) {
        if (e.target) {
          Provider.publishSync(SELECTION_CREATED + BEFORE, e)
          canvas.setActiveObject(e.target)
          canvas.renderAll()
        } else {
          Provider.publishSync(SELECTION_CLEARED + BEFORE, e)
          canvas.discardActiveObject()
        }
      }
    })
  }
  _transformOldJSON(
    { objects = [], background = `transparent`, left = 0, top = 0 } = {
      objects: [],
      background: `transparent`,
      left: 0,
      top: 0,
    }
  ) {
    return {
      objects: objects.map((object) => {
        if (object.type === `text`) {
          object.type = `i-text`
        }
        return {
          ...object,
          left: object.left + left,
          top: object.top + top,
        }
      }),
      background,
    }
  }
  _initOnBeforeUnloadWarning() {
    if (window) {
      window.onbeforeunload = () => {
        if (!this.isDesignSaved) {
          return `Покидая эту страницу, вы потеряете несохранённые изменения`
        }
        return void 0
      }
    }
  }
  _hasStaticObject() {
    return Canvas.getObjects().some((o) => o.static)
  }
}

export default new Mediator()
